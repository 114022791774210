import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import imga from '../Images/cardsimg1.jpg'
import imgb from '../Images/cardsimg2.jpg'
import imgc from '../Images/cardsimg3.png'
import imgd from '../Images/cardsimg4.png'


const Cardscontainer = () => {
    const cardsdata = [
        {

            text: "DOCUMENTATION SIMPLIFIED",
            label: "Book appointments per physician schedule, resources, and/or location..",
            imgs:imga
        },
        {

            text: "INTEGRATED BILLING",
            label: "As Per the traditional way, the provider spends about 15 minutes and 5 minutes..",
             imgs:imgb

        },
        {
            text: "CUSTOMIZED REPORTING",
            label: "Over 500 customizable patient education handouts. The system has the capability to create..",
             imgs:imgc
        },
        {
            text: "ICD-10 READY",
            label: "Integrated drug database, medical calculators and clinical decision criteria...",
             imgs:imgd
        }
    ]
    return (
        <div className='cards-div'>
            <div className='cards-container-section2 pt-5  pb-5'>
                <div className='pt-lg-5 mx-4 px-lg-5 my-5 py-5'>
                    <Row className='cards-row m-lg-5 g-3 px-lg-5'>
                        {cardsdata.map((item, id) => (
                            <Col xl={3} md={6} key={id} >
                                <Card className='custom-card' style={{background:"none",border:"none"}}>
                                    <Card.Img variant="top" src={item.imgs} />
                                    <Card.Body className='px-5'>
                                        <Card.Title  className='pt-4'>{item.text}</Card.Title>
                                        <Card.Text className='py-3'>{item.label}</Card.Text>
                                       
                                   
                                        <Button className='my-5'>Read More</Button>
                                    </Card.Body>
                                </Card>


                                {/* <Card className='custom-card '>
                                    <Card.Img variant="top" src={item.imgs} />
                                    <Card.Body >
                                        <Card.Title >{item.text}</Card.Title>
                                        <Card.Text className='py-3'>{item.label}</Card.Text>
                                        <div className='line'></div>
                                        <div className='btn-class'>
                                        <Button className='btn'>Read More</Button></div>
                                    </Card.Body>
                                </Card> */}
                            </Col>

                            
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Cardscontainer
