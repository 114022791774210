import React from 'react'
import Banner from './Banner'
import Cardscontainer from './Cardscontainer'
import Specialities from './Specialities/Specialities'
import Listsection from './Listsection'
import Sliding from './Sliding'



const Home = () => {
  return (
    <div>
      <Banner />
      <Cardscontainer />
      <Specialities />
      {/* <Servicessection/> */}
      <Listsection />
      <Sliding />
    </div>
  )
}

export default Home
