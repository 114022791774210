import React, { useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
// import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import medicalpng from '../Images/medical.png'
import './Home3.css'
const Banner = () => {

 const navigate=useNavigate()

 // -------------------aos effect-----------------------//
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const Seemore=()=>{
    navigate('/moreinfo')
  }
  return (
    <div className='banner-section3 pt-md-5 pb-lg-5 '>
  
   <div className='px-xl-5'>
   <Row className='row-section1 mx-md-5 px-5 mx-3 mb-lg-5'>
        <Col xl={7} lg={7} md={12}  className='mt-5 pt-5 col-1'>
        {/* <div className='ms-5'> */}
        <h1 className='heading1 mt-5 pt-5 lh-base' style={{fontSize:"65px"}}>
          Total control with integrated Billing module and AR module</h1>
        {/* <h1 className='heading1 headline-text-right d-flex justify-content-end my-5'>MDCare</h1> */}
        {/* </div> */}
          <Button className='btn d-flex p-2 fw-bolder mt-5' >View More
            {/* <span className='ms-2'> 
            <FontAwesomeIcon icon={faArrowAltCircleRight} aria-hidden="true" /></span> */}
          </Button>
        </Col>
        {/* <Col xl={4} lg={5} md={12} data-aos="fade-left" className='pt-md-5 pt-3 px-xl-5  mt-1 mt-xl-5'></Col> */}
        <Col xl={5} lg={5} md={12}  className='pe-xl-5'>
        {/* <img className='mt-5 pt-5' src="https://novant.1onestrong.com/wp-content/uploads/2023/10/Heros-03.png" width="100%"/> */}
        <img className='pe-xl-5 pt-5' src={medicalpng} width="900px"/>
        </Col>
      
      </Row>
   </div>
   {/* <div className='divclass-2'>
    <div style={{height:"400px"}}></div>

   </div> */}
    </div>
  )
}

export default Banner
