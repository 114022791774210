import React, { useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import Aos from 'aos'
import 'aos/dist/aos.css';
import heartimg from '../../Images/1-removebg-preview.png'
// import imga from '../../Images/Asset 2.png'
// import imga from '../../Images/Asset 4.png'
// import imga from '../../Images/Asset 5.png'
import imga from '../../Images/Asset 6 .png'
import img1 from '../../Images/home1img1.png'
import img2 from '../../Images/home1img2.png'
import img3 from '../../Images/home1img3.png'



const Specialities = () => {
  const navigate = useNavigate()
  const handleclick = () => {
    navigate('/specialities')
  }

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className='speciality-section3'>
       {/* <div className='design-pattern'></div> */}
      <Row className='row-section1 justify-content-center  mx-3 py-5'>
        <div className='text-container text-center justify-content-center row py-md-5 py-3'>
          
          <div className='col-lg-6 text-center pt-5 mt-5'>
         
            <h1 className='heading1 text-uppercase py-md-5 py-5'>MDCARE Specialties</h1>
            <img className='heartimg animate__animated animate__heartBeat animate__infinite animate__delay-2s' src={img1} alt='img' width="100%" />

            <img className='img1 animate__animated animate__heartBeat animate__infinite animate__delay-2s' src={img2} alt='img' />
            <p className='pb-md-5'>
              We bring to you an authentic and stable Electronic Medical Record solution with proven technology to meet the specific requirements of each specialty.We will assist you through the maze and bring to you customizable solutions with proven technology for your practice.
            </p>
            <img className='img2 animate__animated animate__heartBeat animate__infinite animate__delay-2s' src={img3} alt='img' />
          </div>

        </div>
      </Row> 
      <div className='py-5'>
      <Row className='row-section2 pb-5 mb-5 pt-5 mt-5  justify-content-center align-items-center'>
        <Col xl={6} md={12} className='col-section1'>
          <div className="img-wrapper">
            <img src={imga} width="100%"/>
            {/* <img src="https://media.gettyimages.com/id/1349348394/photo/female-patient-speaking-with-her-paediatrician-in-a-doctors-office.jpg?s=612x612&w=0&k=20&c=Z5ym-PS_03HGCjSmxsWJ2rxuJchArCZfV8TFyMWQyTg=" alt='img' width="100%" /> */}
          </div>
        </Col>
        <Col xl={5} md={12} className='ms-xl-5 ps-xl-5 '>
          <h1 className='pb-3 pb-md-4'>MDCARE Specialty-Specific EMR key features include:</h1>
          <p className='lh-lg para2'>MDCare, our innovative Electronic Medical Records (EMR) and Practice Management (PMS) platform is developed by an efficient team of physicians from multiple specialties and senior development team with years of healthcare domain expertise. Our services offer unparalleled reliability, comprehensive support, security, ease-of-use, efficiency, and customizability.</p>
          <Button className='btn'>Read More</Button>
          {/* <div className='design-pattern2'></div> */}
        </Col>
      </Row>
      </div>
    </div>
  ) 
}
 
export default Specialities
