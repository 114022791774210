import React, { useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import herosimg from '../Images/home1Heros-03.png'
const Banner = () => {

 const navigate=useNavigate()

 // -------------------aos effect-----------------------//
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const Seemore=()=>{
    navigate('/moreinfo')
  }
  return (
    <div className='banner-section2 pt-md-5 pb-lg-5 px-xl-5'>
  
      <Row className='row-section1 mx-md-5 px-5 mx-3 mb-lg-5'>
        <Col xl={6} lg={7} md={12} data-aos="fade-right" className='mt-5 pt-5 col-1'>
          <h1 className='heading1'>Welcome to</h1>
          <h1 className='heading1 headline-text-right d-flex justify-content-end my-5'>MDCare</h1>
          <Button className='btn d-flex p-2 fw-bolder mt-5' onClick={()=>Seemore()}>View More
            {/* <span className='ms-2'> 
            <FontAwesomeIcon icon={faArrowAltCircleRight} aria-hidden="true" /></span> */}
          </Button>
        </Col>
        {/* <Col xl={4} lg={5} md={12} data-aos="fade-left" className='pt-md-5 pt-3 px-xl-5  mt-1 mt-xl-5'></Col> */}
        <Col xl={6} lg={5} md={12} data-aos="fade-left" className='px-xl-5  mt-5'>
        <img className='mt-5 pt-5' src={herosimg} width="100%"/>
        
          {/* <p className='mt-xl-5 pt-xl-3' style={{color:'black'}}>
            With the ongoing trends in Information Technology, where change is an obsession, time a limited commodity, and quality the hallmark of every business, Vision Infonet Inc has created its niche in the industry by providing healthcare providers the best.

                    </p>
          <Button className='btn d-flex p-2 fw-bolder mt-4' onClick={()=>Seemore()}>See More<span className='ms-2'> 
            <FontAwesomeIcon icon={faArrowAltCircleRight} aria-hidden="true" /></span>
          </Button> */}
        </Col>
      </Row>
    </div>
  )
}

export default Banner
