import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import imga from '../Images/patient-talking-transformed.jpeg'

const Listsection = () => {


    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);


    return (
        <div className='strength-divclass'>
            <div className='strengths-list-section2' style={{paddingTop:"100px"}}>
                <h1 className='text-center my-4 py-md-5 pt-5 mt-5 text-uppercase heading1'>MDCare's strengths</h1>
                <Row className='py-5 px-4 row-section1'>

                    <Col xl={6} lg={6} md={12} className='section1-col1' >

                        <div className='mx-xl-5 px-xl-5 mt-xl-5 pt-xl-4 ' >
                            <h1 className='mb-5'>MDCare's superiority and strengths are</h1>
                            <p>
                                <span className='fw-bold fs-4 me-3'>The Team:</span>
                                <span className='span-text'>consisting of Physicians, Software professionals, Medical Transcriptionists and Certified Medical Billers, headed by a physician.</span>
                            </p>
                            <p className='py-4'>
                                <span className='fw-bold fs-4 me-3'>The Experience:</span>
                                <span className='span-text'>30 years of medical practice, 12 years of software development, 18 years of transcription and 15 years of medical billing and AR processing.</span>
                            </p>
                            <p>
                                <span className='fw-bold fs-4 me-3'>The Product:</span>
                                <span className='span-text'> One of the most advanced, flexible, customizable, robust, user-friendly EMR that will revolutionize your practice and streamline your processes.</span>
                            </p>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} className='mt-lg-3 section1-col2' >
                        <div className='img-wrapper'>
                            <img src={imga} alt='img' width="100%" />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Listsection
