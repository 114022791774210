import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './layout.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
function Header() {

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [bgImage, setBgImage] = useState('');
  const [colorchange, setColor] = useState('');
  const location = useLocation();

  const handleToggle = () => setShowOffcanvas(!showOffcanvas);
  const handleClose = () => setShowOffcanvas(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setShowOffcanvas(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the correct state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Update background image and color based on path
    if (location.pathname === '/home2') {
      // setBgImage('https://media.canva.com/v2/image-resize/format:PNG/height:133/quality:100/uri:s3%3A%2F%2Fmedia-private.canva.com%2FLGkEY%2FMAGKhKLGkEY%2F1%2Fp.png/watermark:F/width:200?csig=AAAAAAAAAAAAAAAAAAAAAKvs3DctoWPJsXkz7BeA1sHJwlBjG6sdEPnX9fwVKTtX&exp=1720613894&osig=AAAAAAAAAAAAAAAAAAAAAG8UdMJ0SI8erhJZyooY1Cy0rzt-Bzuy8B_SymirRzy5&signer=media-rpc&x-canva-quality=thumbnail');
      setBgImage('transparent')
      setColor('#623c25');
    } else if (location.pathname === '/') {
      // setBgImage('https://codex-themes.com/thegem/sites/lms-landing/wp-content/uploads/2020/11/1.png');
      setColor('#ffffff');
      setBgImage('transparent')
    }
  }, [location.pathname]);

  
  return (

    <div className='navbar-section'>
         {/* <Navbar expand="lg" className="header" style={{ backgroundImage: `url(${bgImage})`, color: `${colorchange}` }}> */}
         <Navbar expand="lg" className="header" style={{ backgroundColor: `${bgImage}`, color: `${colorchange}` }}>
        <Container fluid>

          <Navbar.Brand href="/">
            {/* <h1 style={{ color: "white" }}>MDCare</h1> */}
            <img src="https://mdcare.com/images/logo.png" alt="img" width="100%" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" onClick={handleToggle} />
          <Navbar.Offcanvas
            className='navbar-offconvas'
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
            show={showOffcanvas}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                MD Care
              </Offcanvas.Title>
            </Offcanvas.Header>
            <hr className='d-md-none' />
            <Offcanvas.Body>
              <Nav className="mx-auto " >
              <Nav className="mx-auto" style={{  color: colorchange }}>
              <Nav.Link as={Link} to="/" style={{ color: colorchange }}>Home1</Nav.Link>
                <Nav.Link as={Link} to="/home2" style={{ color: colorchange }}>Home2</Nav.Link>
                
              </Nav>
          
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;




// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import './layout.css'
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import { Col, Row } from 'react-bootstrap';
// import { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

// function Header() {
//   const [showOffcanvas, setShowOffcanvas] = useState(false);

//   const handleToggle = () => setShowOffcanvas(!showOffcanvas);
//   const handleClose = () => setShowOffcanvas(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         setShowOffcanvas(false);
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize(); // Call the function initially to set the correct state

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const updateTitleAndUrl = (title, url) => {
//     document.title = title;
//     window.history.pushState({}, title, url);
//   };

//   useEffect(() => {
//     const links = document.querySelectorAll('.navbar-section .nav-link, .navbar-section .dropdown-item');

//     const handleClick = (event) => {
//       event.preventDefault(); // Prevent the default link behavior
//       const title = event.target.getAttribute('title');
//       const url = event.target.getAttribute('href');
//       updateTitleAndUrl(title, url);
//     };

//     links.forEach(link => {
//       link.addEventListener('click', handleClick);
//     });

//     return () => {
//       links.forEach(link => {
//         link.removeEventListener('click', handleClick);
//       });
//     };
//   }, [showOffcanvas]); // Re-run effect when `showOffcanvas` changes

//   return (
//     <div className='navbar-section'>
//       <Navbar expand="lg" className="header">
//         <Container fluid>
//           <Navbar.Brand href="/" title="Home">
//             <h1 style={{ color: "white" }}>MDCare</h1>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" onClick={handleToggle} />
//           <Navbar.Offcanvas
//             className='navbar-offconvas'
//             id="offcanvasNavbar-expand-lg"
//             aria-labelledby="offcanvasNavbarLabel-expand-lg"
//             placement="end"
//             show={showOffcanvas}
//             onHide={handleClose}
//           >
//             <Offcanvas.Header closeButton>
//               <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
//                 MD Care
//               </Offcanvas.Title>
//             </Offcanvas.Header>
//             <hr className='d-md-none' />
//             <Offcanvas.Body>
//               <Nav className="mx-auto " style={{fontFamily:'Libre Baskerville'}}>
//                 <Nav.Link href="/" title="Home">Home</Nav.Link>
//                 <Nav.Link href="/about" title="About">About</Nav.Link>
//                 <NavDropdown title={<><span>Features</span><FontAwesomeIcon icon={faAngleDown} className="ms-2" /></>} id="navbarScrollingDropdown" className="mega-dropdown">
//                   <Container className='my-4 py-md-5'>
//                     <Row>
//                       <Col lg={4}>
//                         <NavDropdown.Item href="/ePrescribing" title="E-Prescribing">E-Prescribing</NavDropdown.Item>
//                         <NavDropdown.Item href="/scheduling" title="Scheduling">Scheduling</NavDropdown.Item>
//                         <NavDropdown.Item href="/clinicaldocumentation" title="Clinical Documentation">Clinical Documentation</NavDropdown.Item>
//                         <NavDropdown.Item href="/pos" title="POS">POS</NavDropdown.Item>
//                         <NavDropdown.Item href="/EMCoding" title="EM Coding">EM Coding</NavDropdown.Item>
//                         <NavDropdown.Item href="/decisionmaking" title="Decision Making">Decision Making</NavDropdown.Item>
//                         <NavDropdown.Item href="/patienteducationforms" title="Patient Education Forms">Patient Education Forms</NavDropdown.Item>
//                       </Col>
//                       <Col lg={4}>
//                         <NavDropdown.Item href="/HL7interface" title="HL7 Interface">HL7 Interface</NavDropdown.Item>
//                         <NavDropdown.Item href="/integratedbillingmodule" title="Integrated Billing Module">Integrated Billing Module</NavDropdown.Item>
//                         <NavDropdown.Item href="/documentediting&management" title="Document Editing Management">Document Editing Management</NavDropdown.Item>
//                         <NavDropdown.Item href="/denialmanagementmodule" title="Denial Management Module">Denial Management Module</NavDropdown.Item>
//                         <NavDropdown.Item href="/hippa" title="HIPAA">HIPAA</NavDropdown.Item>
//                         <NavDropdown.Item href="/additional-features" title="Additional features">Additional features</NavDropdown.Item>
//                       </Col>
//                     </Row>
//                   </Container>
//                 </NavDropdown>
//                 <Nav.Link href="/specialities" title="Specialties">Specialties</Nav.Link>
//                 <Nav.Link href="/roi" title="ROI">ROI</Nav.Link>
//                 <NavDropdown title={<><span>MeaningfulUse</span><FontAwesomeIcon icon={faAngleDown} className="ms-2" /></>} id="navbarScrollingDropdown" className='second-dropdown'>
//                   <div className='dropdown-list'>
//                     <NavDropdown.Item href="/Stimulusinfo" title="Stimulus Info">Stimulus Info</NavDropdown.Item>
//                     <NavDropdown.Item href="/Amountofpayment" title="Amount Of Payment">Amount Of Payment</NavDropdown.Item>
//                   </div>
//                 </NavDropdown>
//                 <NavDropdown title={<><span>Medical Services</span><FontAwesomeIcon icon={faAngleDown} className="ms-2" /></>} id="navbarScrollingDropdown" className="mega-dropdown">
//                   <Container className='my-4 py-md-5'>
//                     <Row>
//                       <Col lg={4} xl={4} xxl={4}>
//                         <NavDropdown.Item href="/" title="Cardiology">Cardiology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Dermatology">Dermatology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Endocrinology">Endocrinology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="ENT/Otolaryngology">ENT/Otolaryngology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Family Practice">Family Practice</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Gastroenterology">Gastroenterology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="General Surgery">General Surgery</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Internal Medicine">Internal Medicine</NavDropdown.Item>
//                       </Col>
//                       <Col lg={3} xl={4} xxl={3}>
//                         <NavDropdown.Item href="/" title="Neurology">Neurology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="OB-GYN">OB-GYN</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Ophthalmology">Ophthalmology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Orthopedics">Orthopedics</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Pain Management">Pain Management</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Pediatric">Pediatric</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Psychiatry">Psychiatry</NavDropdown.Item>
//                       </Col>
//                       <Col lg={4} xl={4} xxl={3}>
//                         <NavDropdown.Item href="/" title="Podiatry">Podiatry</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Pulmonology">Pulmonology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="RIS (Radiology Information System)">RIS (Radiology Information System)</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Urology">Urology</NavDropdown.Item>
//                         <NavDropdown.Item href="/" title="Urgent Care">Urgent Care</NavDropdown.Item>
//                       </Col>
//                     </Row>
//                   </Container>
//                 </NavDropdown>
//                 <Nav.Link href="/mdcareportal" title="Portal">Portal</Nav.Link>
//               </Nav>
//             </Offcanvas.Body>
//           </Navbar.Offcanvas>
//         </Container>
//       </Navbar>
//     </div>
//   );
// }

// export default Header;
