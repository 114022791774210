import React from 'react'
import Banner from './Banner'
import Cardscontainer from './Cardscontainer'
import Specialities from './Specialities/Specialities'




const Home2 = () => {
  return (
    <div>
      <Banner />
      <Cardscontainer/>
      <Specialities/>
    </div>
  )
}

export default Home2
