import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Col, Row } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

const testimonials = [
  { text: ' “I received my incentive money” MDCARE EMR is very easy to work with. I could successfully learn using it, and also got my incentive money for 2011.', name: 'Gene Turner MD' },
  { text: ' “I received my incentive money” MDCARE EMR is very easy to work with. I could successfully learn using it, and also got my incentive money for 2011', name: "Dr. Pradeep" },
  { text: ' “I received my incentive money” MDCARE EMR is very easy to work with. I could successfully learn using it, and also got my incentive money for 2011.', name: "Dr. Goyal" }
];


const Bubble = () => { 
  const size = Math.random() * 50 + 10;
  const left = Math.random() * 100;
  const animationDuration = Math.random() * 5 + 3;

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    left: `${left}%`,
    animationDuration: `${animationDuration}s`,
  };

  return <div className="bubble" style={style}></div>;
};



const Sliding = () => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };


  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);


  const bubbles = Array.from({ length: 20 });
  return (
    <div className="testimonial-slider2  py-md-5 px-md-5">
      <Row className='my-5 py-5 px-md-5 mx-md-3'>
        <Col lg={6}>
          <h1 className='heading1 my-md-5 my-3' data-aos="zoom-in-right">Testimonials</h1>
          <Slider ref={sliderRef} {...settings} >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial">
                <p className='text lh-lg'>{testimonial.text}</p>
                <p className='text-end name'>- {testimonial.name}</p>
              </div>
            ))}
          </Slider>
          <div className="arrows pb-5 mb-5">
            <div className="arrow prev me-3" onClick={previous}>
              {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
              <FontAwesomeIcon icon={faAngleLeft} style={{color:"white"}} />
              
            </div>
            <div className="arrow next" onClick={next}>
              {/* <FontAwesomeIcon icon={faArrowRight} /> */}
              <FontAwesomeIcon icon={faAngleRight} style={{color:"white"}}/>
            </div>
          </div>
        </Col>
        {/* <Col>
          <div className="bubble-box">
            {bubbles.map((_, index) => (
              <Bubble key={index} />
            ))}
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

export default Sliding;
