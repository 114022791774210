import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Layout/Header';
import Home from './components/Home/Home';
import Footer from './Layout/Footer';       
import Home2 from './components/Home2/Home2';

function App() {
  return (
    <div className="App">
      <div className='head-section'>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/home2" element={<Home />} />
      
      </Routes>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
